import React from 'react'

function Step3Details() {
    return (
        <div>
            detail 3
        </div>
    )
}

export default Step3Details
