import React from 'react'

function Step1Details() {
    return (
        <div>
            Steps 1 detail
        </div>
    )
}

export default Step1Details
