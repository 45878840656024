import React from 'react'
import { Paper, CssBaseline } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles'
import { theme, useStyle } from './styles'

// komponen
import Header from '../Header'
import Footer from '../Footer'


export default function AppLayout(props) {
    const { children } = props;
    const classes = useStyle()
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Header />
            <div className={classes.root}>
                <Paper className={classes.paper}>{children}</Paper>
            </div>
            <Footer />
        </ThemeProvider>
    )
}
