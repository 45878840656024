import React from 'react'
import { useFormikContext } from 'formik'
import { Typography, Grid } from '@material-ui/core'

import Step1Details from './Step1Details'
import Step2Details from './Step2Details'
import Step3Details from './Step3Details'
import Step4Details from './Step4Details'
import Step5Details from './Step5Details'
import Step6Details from './Step6Details'
import Step7Details from './Step7Details'


function ReviewSurvey() {

    const { values: formValues } = useFormikContext();

    return (
        <React.Fragment>
            <Typography>
                Review Survey
            </Typography>
            <Grid>
                <Step1Details formValues={formValues} />
                <Step2Details formValues={formValues} />
                <Step3Details formValues={formValues} />
                <Step4Details formValues={formValues} />
                <Step5Details formValues={formValues} />
                <Step6Details formValues={formValues} />
                <Step7Details formValues={formValues} />
            </Grid>
        </React.Fragment>
    )
}

export default ReviewSurvey
