import React from "react";
import { Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead  } from '@material-ui/core';
import { RadioField } from '../../FormFieldsHelpers';

// Table Style
import { StyledTableCell,StyledTableRow } from '../../Layouts/styles'

const pilihanOpsi = [
  {
    value: 'ya',
    label: 'Ya'
  },
  {
    value: 'tidak',
    label: 'Tidak'
  }
];


function createData(no, nama, value) {
  return { no, nama, value};
}

const rows = [
  createData(1,'Program DOTS', 'progdots'),
  createData(2,'Program TBC Resisten', 'progtbcresisten'),
  createData(3,'Poliklinik TBC (Sensitif)', 'politbcsensitif'),
  createData(4,'Poliklinik TBC (resisten)', 'politbcresisten'),
  createData(5,'Rawat inap TBC (sensitif)', 'rawatinaptbcsensitif'),
  createData(6,'Rawat Inap TBC (resisten)', 'rawatinaptbcresisten'),
];

const rowsDua = [
  createData(1,'Klinik Intervensi Paru','KlinikIntervensiParu'),		
	createData(2,'Klinik berhenti merokok','Klinikberhentimerokok'),		
	createData(3,'Klinik Paru','KlinikParu'),
	createData(4,'Klinik paru (infeksi non-TB)','Klinikparu(infeksinon-TB)'),		
	createData(5,'Klinik paru (non infeksi: asma-PPOK, onkologi)','Klinikparu(noninfeksi:asma-PPOK,onkologi)'),		
	createData(6,'Klinik HIV','KlinikHIV'),	
	createData(7,'Poliklinik Penyakit Dalam','PoliklinikPenyakitDalam'),		
	createData(8,'Poliklinik Penyakit Dalam (konsultan)','PoliklinikPenyakitDalam(konsultan)'),		
	createData(9,'Poliklinik Bedah','PoliklinikBedah'),		
	createData(10,'Poliklinik Bedah Toraks','PoliklinikBedahToraks'),		
	createData(11,'Polikinik Anak','PolikinikAnak'),		
	createData(12,'Klinik Anak-Respirasi','KlinikAnak-Respirasi'),		
	createData(13,'Klinik Rehabilitasi Medis','KlinikRehabilitasiMedis'),		
	createData(14,'Instalasi/laboratorium Mikrobiologi','Instalasi/laboratoriumMikrobiologi'),		
	createData(15,'Instalasi/Laboratorium Patologi Klinik','Instalasi/LaboratoriumPatologiKlinik'),		
	createData(16,'Instalasi Radiologi','InstalasiRadiologi'),		
	createData(17,'Layanan Radiologi Intervensi','LayananRadiologiIntervensi'),		
	createData(18,'Layanan Respiratory Intensive Care Unit','LayananRespiratoryIntensiveCareUnit'),		
	createData(19,'Layanan Intensive Care Unit (ICU)','LayananIntensiveCareUnit(ICU)'),		
	createData(20,'Layanan Farmakologi Klinis','LayananFarmakologiKlinis'),		
	createData(21,'Layanan Gawat Darurat','LayananGawatDarurat'),		
	createData(22,'Layanan Gawat Darurat Paru','LayananGawatDaruratParu'),		
	createData(23,'Layanan Edukasi Masyarakat (PKRS)','LayananEdukasiMasyarakat(PKRS)'),	
];

export default function Step3() {
  // const {
  //   formField: {}
  // } = props;

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Jenis Layanan/ Program
      </Typography>
      <Typography variant="h5" gutterBottom>
        Apakah RS anda memiliki layanan/program layanan dibawah ini? 
      </Typography>
      <br />
      <Grid container spacing={3}>
      <React.Fragment>

      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>No</StyledTableCell>
              <StyledTableCell align="right">Layanan TBC</StyledTableCell>
              <StyledTableCell align="left">Pilihan</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow
                key={row.no}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.no}
                </TableCell>
                <TableCell align="right">{row.nama}</TableCell>
                <TableCell align="right">
                    <RadioField 
                      name={`${row.value}`}
                      label={`${row.value}`}
                      data={pilihanOpsi}
                      fullWidth
                    />
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        </TableContainer>
        <br /><br />
        <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>No</StyledTableCell>
              <StyledTableCell align="right">Layanan Paru dan lainnya</StyledTableCell>
              <StyledTableCell align="left">Pilihan</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {rowsDua.map((row) => (
              <StyledTableRow
                key={row.no}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <StyledTableCell component="th" scope="row">
                  {row.no}
                </StyledTableCell>
                <StyledTableCell align="right">{row.nama}</StyledTableCell>
                <StyledTableCell align="right">
                    <RadioField 
                      name={`${row.value}`}
                      label={`${row.value}`}
                      data={pilihanOpsi}
                      fullWidth
                    />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        </TableContainer>

        </React.Fragment>

      </Grid>
    </React.Fragment>
  );
}

