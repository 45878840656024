import React from 'react';
import { Grid, Typography, Table, TableBody, TableContainer, TableHead  } from '@material-ui/core';
import { RadioField } from '../../FormFieldsHelpers';

// Table Style
import { StyledTableCell,StyledTableRow } from '../../Layouts/styles'

function createData(no, nama, value) {
  return { no, nama, value};
}

const rows = [
  createData(1,'Pemeriksaan BTA','PemeriksaanBTA'),
  createData(2,'Pemeriksaan tes cepat molekuler (TCM)','Pemeriksaantescepatmolekuler(TCM)'),
  createData(3,'Pemeriksaan biakan (media padat)','Pemeriksaanbiakan(mediapadat)'),
  createData(4,'Pemeriksaan biakan media cair','Pemeriksaanbiakanmediacair'),
  createData(5,'Pemeriksaan resistensi OAT lini 1 atau 2 atau 1 dan 2','PemeriksaanresistensiOATlini1atau2atau1dan2'),
  createData(6,'Pemeriksaan Whole Genom Sequencing (WGS) M. Tb','PemeriksaanWholeGenomSequencing(WGS)M.Tb'),
  createData(7,'Pemeriksaan LPA lini 1 atau 2 atau lini 1 dan 2','PemeriksaanLPAlini1atau2ataulini1dan2'),
  createData(8,'Pemeriksaan TB Laten (IGRA)','PemeriksaanTBLaten(IGRA)'),
];
const rowsDua = [
  createData(1,'Transthoracal Needle Aspiration (TTNA) tanpa panduan ','TransthoracalNeedleAspiration(TTNA)tanpapanduan'),
  createData(2,'Biopsi paru dengan jarum Core tanpa panduan ','BiopsiparudenganjarumCoretanpapanduan'),
  createData(3,'TTNA dan biopsi paru dipandu USG','TTNAdanbiopsiparudipanduUSG'),
  createData(4,'TTNA dan biopsi paru dipandu CT-Scan ','TTNAdanbiopsiparudipanduCT-Scan'),
  createData(5,'Bronkoskopi ','Bronkoskopi'),
  createData(6,'Bronkoskopi rigid ','Bronkoskopirigid'),
  createData(7,'Bronkoskopi dengan sikatan,bilasan,biopsi ','Bronkoskopidengansikatan,bilasan,biopsi'),
  createData(8,'Bronkoskopi dengan Bronchoalveolar lavage ','BronkoskopidenganBronchoalveolarlavage'),
  createData(9,'Bronkoskopi TBLB flouroskopi ','BronkoskopiTBLBflouroskopi'),
  createData(10,'Bronkoskopi TBLB flouroskopi +  ','BronkoskopiTBLBflouroskopi+'),
  createData(11,'Bronkoskopi TBNA konvensional ','BronkoskopiTBNAkonvensional'),
  createData(12,'Bronkoskopi EBUS TBNA ','BronkoskopiEBUSTBNA'),
  createData(13,'Bronkoskopi termoplasti ','Bronkoskopitermoplasti'),
  createData(14,'Bronkoskopi + ekstraksi benda asing ','Bronkoskopi+ekstraksibendaasing'),
  createData(15,'Bronkoskopi + pemasangan stent ','Bronkoskopi+pemasanganstent'),
  createData(16,'Bronkoskopi + Laser ','Bronkoskopi+Laser'),
  createData(17,'Bronkoskopi + Cryo ','Bronkoskopi+Cryo'),
  createData(18,'Bronkoskopi + Kauter ','Bronkoskopi+Kauter'),
  createData(19,'Bronkoskopi + toilette ','Bronkoskopi+toilette'),
  createData(20,'Bronkoskopi + whole lung lavage','Bronkoskopi+wholelunglavage'),
  createData(21,'Intubasi dengan panduan bronkoskopi  ','Intubasidenganpanduanbronkoskopi'),
  createData(22,'Bronkoskopi + argon plasma coagulation ','Bronkoskopi+argonplasmacoagulation'),
  createData(23,'Bronkoskopi + dilatasi balon ','Bronkoskopi+dilatasibalon'),
  createData(24,'Pleuroskopi ','Pleuroskopi'),
  createData(25,'Biopsi pleura tertutup ','Biopsipleuratertutup'),
  createData(26,'Biopsi pleura tertutup terpandu USG ','BiopsipleuratertutupterpanduUSG'),
  createData(27,'Biopsi pleura terbuka ','Biopsipleuraterbuka'),
  createData(28,'Pemasangan pigtail ','Pemasanganpigtail'),
  createData(29,'Pemasangan IPC ','PemasanganIPC'),
  createData(30,'Water sealed drainage (WSD)','Watersealeddrainage(WSD)'),
  createData(31,'Non-invasive Ventilation (NIV)','Non-invasiveVentilation(NIV)'),
  createData(32,'Pleurodesis','Pleurodesis'),
  createData(33,'Fibrinolitik intrapleural','Fibrinolitikintrapleural'),
  createData(34,'Biopsi Jarum halus (BJH)','BiopsiJarumhalus(BJH)'),
  
];
const rowsTiga = [
  createData(1,'Pemeriksaan kapasitas difusi paru (DLCo)','Pemeriksaankapasitasdifusiparu(DLCo)'),
  createData(2,'Pemeriksaan uji provokasi bronkus','Pemeriksaanujiprovokasibronkus'),
  createData(3,'Pemeriksaan cardiopulmonary excersice (CPX)','Pemeriksaancardiopulmonaryexcersice(CPX)'),
  createData(4,'Pemeriksaan Body pletysmopgrahy','PemeriksaanBodypletysmopgrahy'),
  createData(5,'Pemeriksaan Exhaled breath condensate','PemeriksaanExhaledbreathcondensate'),  
];
const rowsEmpat = [
  createData(1,'Invasive mechanical ventilation','Invasivemechanicalventilation'),
  createData(2,'Terapi oksigen arus rendah','Terapioksigenarusrendah'),
  createData(3,'Terapi oksigen arus tinggi','Terapioksigenarustinggi'),  
];
const rowsLima = [
  createData(1,'Torakotomi dekortikasi ','Torakotomidekortikasi '),
  createData(2,'Torakotomi air/muscle plombage','Torakotomiair/muscleplombage'),
  createData(3,'Torakoplasty','Torakoplasty'),
  createData(4,'Biopsi terbuka kelenjar getah bening colli','Biopsiterbukakelenjargetahbeningcolli'),
  createData(5,'Cavernoplasty','Cavernoplasty'),
  createData(6,'Torakotomi lobektomi untuk batuk darah masif','Torakotomilobektomiuntukbatukdarahmasif'),
  createData(7,'Pneumonectomy untuk destroyed lung','Pneumonectomyuntukdestroyedlung'),
  createData(8,'Trakeoplasti dan operasi jalan napas lainnya yang berkolaborasi dengan paru intervensi','Trakeoplastidanoperasijalannapaslainnyayangberkolaborasidenganparuintervensi'),
  createData(9,'Sleeve lobectomy / sleeve pneumonectomy','Sleevelobectomy/sleevepneumonectomy'),
  createData(10,'Video Assisted Thoracoscopic Surgery','VideoAssistedThoracoscopicSurgery'),
  createData(11,'Nodul paru atau pembesaran KGB mediastinal atau efusi pleura yang belum dapat terdiagnosis etiologinya','NodulparuataupembesaranKGBmediastinalatauefusipleurayangbelumdapatterdiagnosisetiologinya'),  
];

const pilihanOpsi = [
  {
    value: 'ya',
    label: 'Ya'
  },
  {
    value: 'tidak',
    label: 'Tidak'
  }
];

export default function Step5() {
  // const {
  //   formField: {}
  // } = props;
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
      Pelayanan Khusus (diagnosis mikrobiologi, intervensi paru) dan pembedahan toraks
      </Typography>
      <Typography variant="h5" gutterBottom>
      Apakah RS anda memiliki layanan khusus ini?
      </Typography>
      <br />
      <Grid container spacing={3}>
      <React.Fragment>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="a dense table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>No</StyledTableCell>
                <StyledTableCell align="right">Diagnosis Mikrobiologi</StyledTableCell>
                <StyledTableCell align="left">Pilihan</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <StyledTableRow
                  key={row.no}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <StyledTableCell component="th" scope="row">
                    {row.no}
                  </StyledTableCell>
                  <StyledTableCell align="right">{row.nama}</StyledTableCell>
                  <StyledTableCell align="right">
                      <RadioField 
                        name={`${row.value}`}
                        label={`${row.value}`}
                        data={pilihanOpsi}
                        fullWidth
                      />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          </TableContainer>

          {/* Kolom dua */}
          <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="a dense table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>No</StyledTableCell>
                <StyledTableCell align="right">Layanan Tindakan Intervensi Paru</StyledTableCell>
                <StyledTableCell align="left">Pilihan</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {rowsDua.map((row) => (
                <StyledTableRow
                  key={row.no}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <StyledTableCell component="th" scope="row">
                    {row.no}
                  </StyledTableCell>
                  <StyledTableCell align="right">{row.nama}</StyledTableCell>
                  <StyledTableCell align="right">
                      <RadioField 
                        name={`${row.value}`}
                        label={`${row.value}`}
                        data={pilihanOpsi}
                        fullWidth
                      />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          </TableContainer>

          {/* Kolom tiga */}
          <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="a dense table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>No</StyledTableCell>
                <StyledTableCell align="right">Layanan Faal Paru</StyledTableCell>
                <StyledTableCell align="left">Pilihan</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {rowsTiga.map((row) => (
                <StyledTableRow
                  key={row.no}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <StyledTableCell component="th" scope="row">
                    {row.no}
                  </StyledTableCell>
                  <StyledTableCell align="right">{row.nama}</StyledTableCell>
                  <StyledTableCell align="right">
                      <RadioField 
                        name={`${row.value}`}
                        label={`${row.value}`}
                        data={pilihanOpsi}
                        fullWidth
                      />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          </TableContainer>

          {/* Kolom empat */}
          <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="a dense table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>No</StyledTableCell>
                <StyledTableCell align="right">Pelayanan Paru Intensif</StyledTableCell>
                <StyledTableCell align="left">Pilihan</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {rowsEmpat.map((row) => (
                <StyledTableRow
                  key={row.no}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <StyledTableCell component="th" scope="row">
                    {row.no}
                  </StyledTableCell>
                  <StyledTableCell align="right">{row.nama}</StyledTableCell>
                  <StyledTableCell align="right">
                      <RadioField 
                        name={`${row.value}`}
                        label={`${row.value}`}
                        data={pilihanOpsi}
                        fullWidth
                      />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          </TableContainer>

          {/* Kolom lima */}
          <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="a dense table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>No</StyledTableCell>
                <StyledTableCell align="right">Layanan Pembedahan Toraks</StyledTableCell>
                <StyledTableCell align="left">Pilihan</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {rowsLima.map((row) => (
                <StyledTableRow
                  key={row.no}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <StyledTableCell component="th" scope="row">
                    {row.no}
                  </StyledTableCell>
                  <StyledTableCell align="right">{row.nama}</StyledTableCell>
                  <StyledTableCell align="right">
                      <RadioField 
                        name={`${row.value}`}
                        label={`${row.value}`}
                        data={pilihanOpsi}
                        fullWidth
                      />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          </TableContainer>

        </React.Fragment>
      </Grid>
    </React.Fragment>
  );
}
