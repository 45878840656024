import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { InputField, SelectField } from '../../FormFieldsHelpers';

// Jenis Akreditasi
const pencatatanlaporan = [
  {
    value: 'rutinmelaporkan',
    label: 'Rutin Melaporkan'
  },
  {
    value: 'laportidakrutin',
    label: 'Melaporkan tetapi tidak rutin'
  },
  {
    value: 'tidakpernahlapor',
    label: 'Tidak pernah melaporkan'
  }
];

export default function Step7() {
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Pencatatan dan Pelaporan TBC (SITB)
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
        <SelectField
            name='pencatatanlaporan'
            label='Pencatatan Laporan'
            data={pencatatanlaporan}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <InputField name='laporanText' label='Kendala Laporan' fullWidth />
        </Grid>     
      </Grid>
    </React.Fragment>
  );
}