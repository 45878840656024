import * as Yup from 'yup'
import surveyFormModel from './surveyFormModel'

const {
    formField: {
        namaRs,
        namaPetugas,
        nomorHpPetugas,
        jenisAkreditasi
    }
} = surveyFormModel;

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;


const validationSchema = [
    Yup.object().shape({
        [namaRs.name]: Yup.string().required(`${namaRs.requiredErrorMsg}`),
        [namaPetugas.name]: Yup.string().required(`${namaPetugas.requiredErrorMsg}`),
        [nomorHpPetugas.name]: Yup.string().required(`${nomorHpPetugas.requiredErrorMsg}`).matches(phoneRegExp, 'Nomor Handphone tidak valid bro..').min(9, "kependekan bro..").max(14, "kepanjangan bro.."), // source: https://stackoverflow.com/a/63317462
        [jenisAkreditasi.name]: Yup.string().required(`${jenisAkreditasi.requiredErrorMsg}`),
    })
]

export default validationSchema