import React, {useState} from 'react'
import { Stepper, Step, StepLabel, Button, Typography, CircularProgress } from '@material-ui/core'
import { Formik, Form } from 'formik'

// API
import backendAPI from '../../api/website'

// Komponen Step
import Step1 from './StepForms/Step1'
import Step2 from './StepForms/Step2'
import Step3 from './StepForms/Step3'
import Step4 from './StepForms/Step4'
import Step5 from './StepForms/Step5'
import Step6 from './StepForms/Step6'
import Step7 from './StepForms/Step7'
import Step8 from './StepForms/Step8'

import ReviewSurvey from './ReviewSurvey'
import SurveySuccess from './SurveySuccess'

// MaterialUI
import useStyles from './styles'

// Core
import validationSchema from './Utils/validationSchema'
import surveyFormModel from './Utils/surveyFormModel'
import formInitialValues from './Utils/formInitialValues'

const steps = ['Step1','Step2','Step3','Step4','Step5','Step6','Step7','Step8','Review'];
const { formField, formId } = surveyFormModel;

function getStepContent(step) {
    switch (step) {
        case 0:
            return <Step1 formField={formField} />;
        case 1:
            return <Step2 formField={formField} />;
        case 2:
            return <Step3 formField={formField} />;
        case 3:
            return <Step4 formField={formField} />;
        case 4:
            return <Step5 formField={formField} />;
        case 5:
            return <Step6 formField={formField} />;
        case 6:
            return <Step7 formField={formField} />;
        case 7:
            return <Step8 formField={formField} />;
        case 8:
            return <ReviewSurvey />;
        default:
            return "Unknown step";
    }
}

export default function FormSurveyPage() {

    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0); // default 0
    const currentValidationSchema = validationSchema[activeStep];
    const isLastStep = activeStep === steps.length - 1; // cek akhir

    function _sleep(ms){
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    // submit
    async function _submitFrom(val,act) {
        await _sleep(1000);
        // alert(JSON.stringify(val,null,2));

        const formData = new FormData();
        formData.append("data", JSON.stringify(val));

        try {
            const response = await backendAPI.post("/surveiOnline/", formData, {
            //   mode: "no-cors",
              method: "POST",
              body: formData,
            });
            console.log("Submitting complete", response.statusText);

            act.setSubmitting(false);
            setActiveStep(activeStep + 1);
      
        } catch (e) {
            console.log(e);
        }

    }

    // onClick submit
    function _handleSubmit(val,act) {
        if(isLastStep){
            _submitFrom(val,act);
        }else{
            setActiveStep(activeStep + 1);
            act.setTouched({});
            act.setSubmitting(false);
        }
    }

    function _handleBack() {
        setActiveStep(activeStep - 1);
    }

    return (
        <React.Fragment>
            <Typography component="h1" variant="h4" align="center">Survey TBC</Typography>
            <Stepper activeStep={activeStep} className={classes.stepper}>
                {steps.map(label => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <React.Fragment>
                {activeStep === steps.length ? (
                    <SurveySuccess />
                ) : (
                    <Formik
                        initialValues={formInitialValues}
                        validationSchema={currentValidationSchema}
                        onSubmit={_handleSubmit}
                    >
                        {({ isSubmitting }) => (
                            <Form id={formId}>
                                {getStepContent(activeStep)}

                                <div className={classes.wrapper}>
                                    {activeStep !== 0 && (
                                        <Button onClick={_handleBack} className={classes.button}>Back</Button>
                                    )}

                                    <div className={classes.wrapper}>
                                        <Button
                                            disabled={isSubmitting}
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                        >
                                            {isLastStep ? 'Simpan' : 'Next'} 
                                        
                                        </Button>

                                        {isSubmitting && (
                                            <CircularProgress 
                                                size={24}
                                                className={classes.buttonProgress}
                                            />
                                        )}
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                )}

            </React.Fragment>
        </React.Fragment>
    )

}

