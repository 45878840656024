const surveyFormModal = {
    formId: 'surveyForm',
    formField: {
        namaRs:{
            name: 'namaRs',
            label: 'Nama Rumah Sakit',
            requiredErrorMsg: 'Nama Rumah Sakit Wajib Isi!'
        },
        namaPetugas:{
            name: 'namaPetugas',
            label: 'Nama Petugas RS',
            requiredErrorMsg: 'Nama Petugas RS Wajib Isi!'
        },
        nomorHpPetugas:{
            name: 'nomorHpPetugas',
            label: 'Nomor HP Petugas RS',
            requiredErrorMsg: 'Nomor HP Petugas RS Wajib Isi!'
        },
        jenisAkreditasi: {
            name: 'jenisAkreditasi',
            label: 'Jenis Akreditasi yang diraih',
            requiredErrorMsg: 'Jenis Akreditasi RS Wajib Isi!'
        }
    }
}

export default surveyFormModal