import React from 'react'

function Step7Details() {
    return (
        <div>
            detail 7
        </div>
    )
}

export default Step7Details
