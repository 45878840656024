import React from 'react'

function Step2Details() {
    return (
        <div>
            detail 2
        </div>
    )
}

export default Step2Details
