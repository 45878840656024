import React from 'react';
import { Grid, Typography, Table, TableBody, TableContainer, TableHead  } from '@material-ui/core';
import { RadioField, InputField } from '../../FormFieldsHelpers';

// Table Style
import { StyledTableCell,StyledTableRow } from '../../Layouts/styles'

function createData(no, nama, value) {
  return { no, nama, value};
}

const rows = [
  createData(1,'Dokter umum','Dokterumum'),
  createData(2,'Dokter umum (yang sudah mengikuti pelatihan TB-DOTS)','Dokterumum(yangsudahmengikutipelatihanTB-DOTS)'),
  createData(3,'Dokter umum (yang sudah mengikuti pelatihan TB-RO)','Dokterumum(yangsudahmengikutipelatihanTB-RO)'),
  createData(4,'Dokter Spesialis Paru','DokterSpesialisParu'),
  createData(5,'Dokter Spesialis Paru (yang sudah mengikuti Pelatihan TB-RO)','DokterSpesialisParu(yangsudahmengikutiPelatihanTB-RO)'),
  createData(6,'Dokter Spesialis Paru (konsultan Infeksi Paru)','DokterSpesialisParu(konsultanInfeksiParu)'),
  createData(7,'Dokter Spesialis Paru (konsultan intervensi)','DokterSpesialisParu(konsultanintervensi)'),
  createData(8,'Dokter Spesialis Paru (konsultan Asma-PPOK)','DokterSpesialisParu(konsultanAsma-PPOK)'),
  createData(9,'Dokter Spesialis Paru (konsultan Paru Kerja)','DokterSpesialisParu(konsultanParuKerja)'),
  createData(10,'Dokter Spesialis Paru (konsultan lainnya)','DokterSpesialisParu(konsultanlainnya)'),
  createData(11,'Dokter Spesialis Penyakit Dalam','DokterSpesialisPenyakitDalam'),
  createData(12,'Dokter Spesialis Penyakit Dalam (konsultan Paru)','DokterSpesialisPenyakitDalam(konsultanParu)'),
  createData(13,'Dokter Spesialis Penyakit Dalam (konsultan Endokrin)','DokterSpesialisPenyakitDalam(konsultanEndokrin)'),
  createData(14,'Dokter Spesialis Penyakit Dalam (konsultan Alergi Imunologi)','DokterSpesialisPenyakitDalam(konsultanAlergiImunologi)'),
  createData(15,'Dokter Spesialis Penyakit Dalam (konsultan Ginjal Hipertensi)','DokterSpesialisPenyakitDalam(konsultanGinjalHipertensi)'),
  createData(16,'Dokter Spesialis Syaraf','DokterSpesialisSyaraf'),
  createData(17,'Dokter Spesialis THT','DokterSpesialisTHT'),
  createData(18,'Dokter Spesialis Mata','DokterSpesialisMata'),
  createData(19,'Dokter Spesialis Obstetri dan Ginekologi','DokterSpesialisObstetridanGinekologi'),
  createData(20,'Dokter Spesialis Kulit dan Kelamin','DokterSpesialisKulitdanKelamin'),
  createData(21,'Dokter Spesialis Anak','DokterSpesialisAnak'),
  createData(22,'Dokter Spesialis Anak (konsultan Respirasi)','DokterSpesialisAnak(konsultanRespirasi)'),
  createData(23,'Dokter Spesialis Mikrobiologi Klinis','DokterSpesialisMikrobiologiKlinis'),
  createData(24,'Dokter Spesialis Patologi Klinis','DokterSpesialisPatologiKlinis'),
  createData(25,'Dokter Spesialis Bedah','DokterSpesialisBedah'),
  createData(26,'Dokter Spesialis Bedah Orthopedi','DokterSpesialisBedahOrthopedi'),
  createData(27,'Dokter Spesialis Bedah Toraks','DokterSpesialisBedahToraks'),
  createData(28,'Dokter Spesialis Rehabilitasi Medis','DokterSpesialisRehabilitasiMedis'),
  createData(29,'Dokter Spesialis Radiologi','DokterSpesialisRadiologi'),
  createData(30,'Dokter Spesialis Radiologi (konsultan Intervensi Radiologi)','DokterSpesialisRadiologi(konsultanIntervensiRadiologi)'),
  createData(31,'Perawat bedah Toraks','PerawatbedahToraks'),
  createData(32,'Perawat Poliklinik TBC','PerawatPoliklinikTBC'),
  createData(33,'Perawat Poliklinik Paru','PerawatPoliklinikParu'),
  createData(34,'Data Officer TBC (DO)','DataOfficerTBC(DO)'),
  createData(35,'Data Officer TBC Sensitif Obat','DataOfficerTBCSensitifObat'),
  createData(36,'Data Officer TBC Resisten Obat','DataOfficerTBCResistenObat'),    
];

const pilihanOpsi = [
  {
    value: 'ya',
    label: 'Ya'
  },
  {
    value: 'tidak',
    label: 'Tidak'
  }
];

export default function Step5() {
  // const {
  //   formField: {}
  // } = props;
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
      Sumber Daya Manusia
      </Typography>
      <Typography variant="h5" gutterBottom>
      Apakah RS anda memiliki sumber daya manusia dibawah ini? Dan sebutkan jumlahnya
      </Typography>
      <br />
      <Grid container spacing={3}>
      <React.Fragment>

      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="a dense table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>No</StyledTableCell>
              <StyledTableCell align="right">Tim</StyledTableCell>
              <StyledTableCell align="left">Pilihan</StyledTableCell>
              <StyledTableCell align="left">Jumlah</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow
                key={row.no}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <StyledTableCell component="th" scope="row">
                  {row.no}
                </StyledTableCell>
                <StyledTableCell align="right">{row.nama}</StyledTableCell>
                <StyledTableCell align="right">
                    <RadioField 
                      name={`${row.value}`}
                      label={`${row.value}`}
                      data={pilihanOpsi}
                      fullWidth
                    />
                </StyledTableCell>
                <StyledTableCell>
                  <InputField name={`jumlah_${row.value}`} label='Jumlah' fullWidth />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        </TableContainer>
        </React.Fragment>

      </Grid>
    </React.Fragment>
  );
}
