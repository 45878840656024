import surveyFormModel from "./surveyFormModel"

const {
    formField: {
        namaRs,
        namaPetugas,
        nomorHpPetugas,
        jenisAkreditasi
    }
} = surveyFormModel;

const formInitialValues = {
    [namaRs.name]: '',
    [namaPetugas.name]: '',
    [nomorHpPetugas.name]: '',
    [jenisAkreditasi.name]: ''
}

export default formInitialValues