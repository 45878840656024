import React from 'react';
import { Typography } from '@material-ui/core';

function SurveySuccess() {
  return (
    <React.Fragment>
      <Typography variant="h5" gutterBottom>
        Terima Kasih atas Kesediaan waktu Anda dalam Mengisi Kuesioner ini!
      </Typography>
      <Typography variant="subtitle1">
        Semua Data akan Tersimpan diserver Kami dan Dijaga dengan Baik.
      </Typography>
    </React.Fragment>
  );
}

export default SurveySuccess;