import React from 'react';
import { Grid, Typography, Table, TableBody, TableContainer, TableHead  } from '@material-ui/core';
import { RadioField } from '../../FormFieldsHelpers';

// Table Style
import { StyledTableCell,StyledTableRow } from '../../Layouts/styles'

function createData(no, nama, value) {
  return { no, nama, value};
}

const rows = [
  createData(1,'Tim TBC-DOTS', 'tbdots'),
  createData(2,'Tim TBC-Resisten Obat (Bersama Tim Ahli Klinis)', 'tbresisten'),
  createData(3,'Tim Berhenti Merokok', 'berhentimerokok'),
  createData(4,'Tim Transplantasi Paru', 'Transplantasiparu'),
  createData(5,'Tim lung Stem Cell', 'lungcell'),
  createData(6,'Tim Pencegahan dan Pengendalian (PPI) RS', 'ppirs'),
  createData(7,'Tim/Pokja HIV','pokjahiv'),
];

const pilihanOpsi = [
  {
    value: 'ya',
    label: 'Ya'
  },
  {
    value: 'tidak',
    label: 'Tidak'
  }
];

export default function Step2() {
  // const {
  //   formField: {}
  // } = props;
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        TIM
      </Typography>
      <Typography variant="h5" gutterBottom>
        Apakah RS anda memiliki tim (dan telah disahkan dengan SK)?
      </Typography>
      <br />
      <Grid container spacing={3}>
      <React.Fragment>

      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="a dense table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>No</StyledTableCell>
              <StyledTableCell align="right">Tim</StyledTableCell>
              <StyledTableCell align="left">Pilihan</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow
                key={row.no}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <StyledTableCell component="th" scope="row">
                  {row.no}
                </StyledTableCell>
                <StyledTableCell align="right">{row.nama}</StyledTableCell>
                <StyledTableCell align="right">
                    <RadioField 
                      name={`${row.value}`}
                      label={`${row.value}`}
                      data={pilihanOpsi}
                      fullWidth
                    />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        </TableContainer>
        </React.Fragment>

      </Grid>
    </React.Fragment>
  );
}
