import React from 'react';
import { Grid, Typography, Table, TableBody, TableContainer, TableHead  } from '@material-ui/core';
import { RadioField } from '../../FormFieldsHelpers';

// Table Style
import { StyledTableCell,StyledTableRow } from '../../Layouts/styles'

function createData(no, nama, value) {
  return { no, nama, value};
}

const rows = [
  createData(1,'Panduan Praktik Klinik TBC sensitif Obat','PanduanPraktikKlinikTBCsensitifObat'),
  createData(2,'Panduan Praktik Klinik TBC Resisten Obat','PanduanPraktikKlinikTBCResistenObat'),
  createData(3,'Panduan Praktik Klinik TBC ekstraparu','PanduanPraktikKlinikTBCekstraparu'),
  createData(4,'Panduan Praktik Klinik TBC Anak','PanduanPraktikKlinikTBCAnak'),
  createData(5,'SOP TBC Sensitif Obat','SOPTBCSensitifObat'),
  createData(6,'SOP TBC Resisten Obat','SOPTBCResistenObat'),
  createData(7,'SOP PPI transmisi lewat udara','SOPPPItransmisilewatudara'),
  createData(8,'PPK Bedah Toraks','PPKBedahToraks'),
  createData(9,'PPK Mikrobiologi (diagnosis TBC)','PPKMikrobiologi(diagnosisTBC)'),
  createData(10,'Clinical Pathway TBC Sensitif Obat','ClinicalPathwayTBCSensitifObat'),
  createData(11,'Clinical Pathway TBC Resisten Obat','ClinicalPathwayTBCResistenObat'),   
];

const pilihanOpsi = [
  {
    value: 'ya',
    label: 'Ya'
  },
  {
    value: 'tidak',
    label: 'Tidak'
  }
];

export default function Step6() {
  // const {
  //   formField: {}
  // } = props;
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
      Panduan Praktik Klinik (PPK), SOP
      </Typography>
      <Typography variant="h5" gutterBottom>
      Apakah RS anda memiliki PPK/SOP dibawah ini? 
      </Typography>
      <br />
      <Grid container spacing={3}>
      <React.Fragment>

      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="a dense table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>No</StyledTableCell>
              <StyledTableCell align="right">Jenis Layanan/program</StyledTableCell>
              <StyledTableCell align="left">Pilihan</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow
                key={row.no}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <StyledTableCell component="th" scope="row">
                  {row.no}
                </StyledTableCell>
                <StyledTableCell align="right">{row.nama}</StyledTableCell>
                <StyledTableCell align="right">
                    <RadioField 
                      name={`${row.value}`}
                      label={`${row.value}`}
                      data={pilihanOpsi}
                      fullWidth
                    />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        </TableContainer>
        </React.Fragment>

      </Grid>
    </React.Fragment>
  );
}