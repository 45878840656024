import React from 'react'

function Step6Details() {
    return (
        <div>
            details 6
        </div>
    )
}

export default Step6Details
