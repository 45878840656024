import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { InputField, SelectField } from '../../FormFieldsHelpers';

// Jenis Akreditasi
const akreditasi = [
  {
    value: 'tkparipurna',
    label: 'Tingkat Paripurna'
  },
  {
    value: 'tkutama',
    label: 'Tingkat Utama'
  },
  {
    value: 'tkmadya',
    label: 'Tingkat Madya'
  },
  {
    value: 'tkdasar',
    label: 'Tingkat Dasar'
  },
  {
    value: 'tidaklulus',
    label: 'Tidak lulus akreditasi'
  }
];

export default function Step1(props) {
  const {
    formField: {
      namaRs,
      namaPetugas,
      nomorHpPetugas,
      jenisAkreditasi
    }
  } = props;
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Akreditasi RS
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <InputField name={namaRs.name} label={namaRs.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={namaPetugas.name} label={namaPetugas.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={nomorHpPetugas.name} label={nomorHpPetugas.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
        <SelectField
            name={jenisAkreditasi.name}
            label={jenisAkreditasi.label}
            data={akreditasi}
            fullWidth
          />
        </Grid>         
      </Grid>
    </React.Fragment>
  );
}