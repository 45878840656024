import React from "react";
import { Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead  } from '@material-ui/core';
import { RadioField } from '../../FormFieldsHelpers';

// Table Style
import { StyledTableCell,StyledTableRow } from '../../Layouts/styles'

const pilihanOpsi = [
  {
    value: 'ya',
    label: 'Ya'
  },
  {
    value: 'tidak',
    label: 'Tidak'
  }
];


function createData(no, nama, value) {
  return { no, nama, value};
}

const rows = [
  createData(1,'Ruang bronkoskopi','Ruangbronkoskopi'),
  createData(2,'Alat-alat bronkoskopi','Alat-alatbronkoskopi'),
  createData(3,'Alat-alat bronkoskopi forcep','Alat-alatbronkoskopiforcep'),
  createData(4,'Alat-alat bronkoskopi kateurisasi, laser','Alat-alatbronkoskopikateurisasi,laser'),
  createData(5,'Alat-alat bronkoskopi stent','Alat-alatbronkoskopistent'),
  createData(6,'Alat-alat pleuroskopi','Alat-alatpleuroskopi'),
  createData(7,'Alat-alat torakoskopi','Alat-alattorakoskopi'),
  createData(8,'Alat-alat Transthoracal Needle Aspiration (TBNA)','Alat-alatTransthoracalNeedleAspiration(TBNA)'),
  createData(9,'Alat-alat Transbronchial lung biopsy (TBLB)','Alat-alatTransbronchiallungbiopsy(TBLB)'),
  createData(10,'Alat-alat Endobronkial ultrasound (EBUS)','Alat-alatEndobronkialultrasound(EBUS)'),
  createData(11,'Alat Continus suction','AlatContinussuction'),  
];

const rowsDua = [
  createData(1,'Mesin dan alat spirometri','Mesindanalatspirometri'),
  createData(2,'Mesin dan alat kapasitas difusi (DLCO)','Mesindanalatkapasitasdifusi(DLCO)'),
  createData(3,'Mesin dan alat provokasi bronkus','Mesindanalatprovokasibronkus'),
  createData(4,'Mesin dan alat Body pletysmopgrahy','MesindanalatBodypletysmopgrahy'),
  createData(5,'Mesin dan alat Exhaled breath condensate','MesindanalatExhaledbreathcondensate'),  
];

const rowsTiga = [
  createData(1,'Mikroskopi dan reagen pemeriksaan BTA','MikroskopidanreagenpemeriksaanBTA'),
  createData(2,'Mesin dan alat/bahan Tes Cepat Molekuler (TCM)/GenXpert','Mesindanalat/bahanTesCepatMolekuler(TCM)/GenXpert'),
  createData(3,'Mesin, alat dan bahan pemeriksaan biakan (kultur) M.Tb (Padat/LJ)','Mesin,alatdanbahanpemeriksaanbiakan(kultur)M.Tb(Padat/LJ)'),
  createData(4,'Mesin, alat dan bahan pemeriksaan biakan (kultur) M.Tb (cair/MGITT)','Mesin,alatdanbahanpemeriksaanbiakan(kultur)M.Tb(cair/MGITT)'),
  createData(5,'Mesin, alat dan bahan pemeriksaan resistensi Lini 1 dan 2','Mesin,alatdanbahanpemeriksaanresistensiLini1dan2'),
  createData(6,'Mesin, alat dan bahan pemeriksaan line probe assay (LPA1 atau 2 atau keduanya)','Mesin,alatdanbahanpemeriksaanlineprobeassay(LPA1atau2ataukeduanya)'),
  createData(7,'Mesin, alat dan bahan pemeriksaan Genoscholar','Mesin,alatdanbahanpemeriksaanGenoscholar'),
  createData(8,'Alat dan bahan tes Mantoux','AlatdanbahantesMantoux'),
  createData(9,'Mesin, alat dan bahan pemeriksaan TB laten (IGRA)','Mesin,alatdanbahanpemeriksaanTBlaten(IGRA)'),  
];

const rowsEmpat = [
  createData(1,'Mesin dan alat radiologi','Mesindanalatradiologi'),
  createData(2,'Mesin dan alat CT Scan','MesindanalatCTScan'),
  createData(3,'Mesin dan alat MRI','MesindanalatMRI'),  
];

const rowsLima = [
  createData(1,'Kamar operasi tekanan positif dengan anteroom tekanan negatif Set','KamaroperasitekananpositifdengananteroomtekanannegatifSet'),
  createData(2,'Instrumen bedah torakotomi dewasa','Instrumenbedahtorakotomidewasa'),
  createData(3,'Instrumen bedah torakotomi anak/bayi','Instrumenbedahtorakotomianak/bayi'),
  createData(4,'Tower endoscopy lengkap dengan instrumen-instrumen untuk VATS','Towerendoscopylengkapdenganinstrumen-instrumenuntukVATS'),
  createData(5,'Endoscopic stapler untuk VATS','EndoscopicstapleruntukVATS'),
  createData(6,'Endotracheal tube double lumen','Endotrachealtubedoublelumen'),  
];

const rowsEnam = [
  createData(1,'Alat treadmill','Ruangbronkoskopi'),
  createData(2,'Sepeda Statis','Alat-alatbronkoskopi'),
  createData(3,'Upperbody and lowerbody theraband','Alat-alatbronkoskopiforcep'),
  createData(4,'Cough assist, laser','Alat-alatbronkoskopikateurisasi,laser'),
  createData(5,'Chest vibration','Alat-alatbronkoskopistent'),
  createData(6,'Hidrasun Thorasic relaxation','Alat-alatpleuroskopi'),
  createData(7,'Neuromuscular training (NMES)','Alat-alattorakoskopi'),
  createData(8,'Biofeedback manometer','Alat-alatTransthoracalNeedleAspiration(TBNA)'),
];

export default function Step4() {
  // const {
  //   formField: {}
  // } = props;

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Sarana-prasarana (fasilitas)
      </Typography>
      <Typography variant="h5" gutterBottom>
      Apakah RS anda memiliki sarana/prasarana dibawah ini? 
      </Typography>
      <br />
      <Grid container spacing={3}>
      <React.Fragment>

      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>No</StyledTableCell>
              <StyledTableCell align="right">Paru Intervensi dan Gawat Napas</StyledTableCell>
              <StyledTableCell align="left">Pilihan</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow
                key={row.no}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.no}
                </TableCell>
                <TableCell align="right">{row.nama}</TableCell>
                <TableCell align="right">
                    <RadioField 
                      name={`${row.value}`}
                      label={`${row.value}`}
                      data={pilihanOpsi}
                      fullWidth
                    />
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        </TableContainer>
        <br /><br />
        <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>No</StyledTableCell>
              <StyledTableCell align="right">Faal Paru</StyledTableCell>
              <StyledTableCell align="left">Pilihan</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {rowsDua.map((row) => (
              <StyledTableRow
                key={row.no}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <StyledTableCell component="th" scope="row">
                  {row.no}
                </StyledTableCell>
                <StyledTableCell align="right">{row.nama}</StyledTableCell>
                <StyledTableCell align="right">
                    <RadioField 
                      name={`${row.value}`}
                      label={`${row.value}`}
                      data={pilihanOpsi}
                      fullWidth
                    />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        </TableContainer>

        <br /><br />
        <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>No</StyledTableCell>
              <StyledTableCell align="right">Mikrobiologi/Patologi Klinik</StyledTableCell>
              <StyledTableCell align="left">Pilihan</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {rowsTiga.map((row) => (
              <StyledTableRow
                key={row.no}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <StyledTableCell component="th" scope="row">
                  {row.no}
                </StyledTableCell>
                <StyledTableCell align="right">{row.nama}</StyledTableCell>
                <StyledTableCell align="right">
                    <RadioField 
                      name={`${row.value}`}
                      label={`${row.value}`}
                      data={pilihanOpsi}
                      fullWidth
                    />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        </TableContainer>

        <br /><br />
        <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>No</StyledTableCell>
              <StyledTableCell align="right">Radiologi</StyledTableCell>
              <StyledTableCell align="left">Pilihan</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {rowsEmpat.map((row) => (
              <StyledTableRow
                key={row.no}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <StyledTableCell component="th" scope="row">
                  {row.no}
                </StyledTableCell>
                <StyledTableCell align="right">{row.nama}</StyledTableCell>
                <StyledTableCell align="right">
                    <RadioField 
                      name={`${row.value}`}
                      label={`${row.value}`}
                      data={pilihanOpsi}
                      fullWidth
                    />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        </TableContainer>

        <br /><br />
        <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>No</StyledTableCell>
              <StyledTableCell align="right">Bedah Toraks</StyledTableCell>
              <StyledTableCell align="left">Pilihan</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {rowsLima.map((row) => (
              <StyledTableRow
                key={row.no}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <StyledTableCell component="th" scope="row">
                  {row.no}
                </StyledTableCell>
                <StyledTableCell align="right">{row.nama}</StyledTableCell>
                <StyledTableCell align="right">
                    <RadioField 
                      name={`${row.value}`}
                      label={`${row.value}`}
                      data={pilihanOpsi}
                      fullWidth
                    />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        </TableContainer>

        <br /><br />
        <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>No</StyledTableCell>
              <StyledTableCell align="right">Rehabilitasi Medis</StyledTableCell>
              <StyledTableCell align="left">Pilihan</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {rowsEnam.map((row) => (
              <StyledTableRow
                key={row.no}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <StyledTableCell component="th" scope="row">
                  {row.no}
                </StyledTableCell>
                <StyledTableCell align="right">{row.nama}</StyledTableCell>
                <StyledTableCell align="right">
                    <RadioField 
                      name={`${row.value}`}
                      label={`${row.value}`}
                      data={pilihanOpsi}
                      fullWidth
                    />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        </TableContainer>

        </React.Fragment>

      </Grid>
    </React.Fragment>
  );
}
  
