import React from 'react'

function Step4Details() {
    return (
        <div>
            details 4
        </div>
    )
}

export default Step4Details
