import React from 'react'

function Step5Details() {
    return (
        <div>
            details 5
        </div>
    )
}

export default Step5Details
